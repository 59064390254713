require('./bootstrap');

// fortawesome
require('@fortawesome/fontawesome-free/js/all.js');

// SweetAlert2
import Swal from "sweetalert2";
window.Swal = Swal;

// quill
require('./quill.js');
window.Quill = require('Quill');
